import React from 'react'
import { Box } from 'theme-ui'
export default function BodyText({ body, ...rest }) {
  return (
    <Box
      className='body'
      dangerouslySetInnerHTML={{ __html: body }}
      {...rest}
      sx={{ ...styles.container, ...rest.sx }}
    />
  )
}

const styles = {
  container: {
    marginBottom: '1rem',
  },
}
