import React, { useContext } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import ContactForm from '../components/ContactForm'
// import ContentBlockCenter from '../components/ContentBlocks/ContentBlockCenter'
import PageHero from '../components/Heros/PageHero'
import TextContent from '../components/Text/TextContent'
import Phone from '../components/UI/Phone'
import Address from '../components/UI/Address'
import HoursDisplay from '../components/Hours/HoursDisplay'
import { store } from '../context/store'

export default function Contact() {
  const { globalState, dispatch } = useContext(store)

  const { businessData } = globalState
  return (
    <Box sx={styles.container}>
      <PageHero imageID={'contact-pagehero'} />
      {/* <ContentBlockCenter storyId={'a-art-t0S-5y5lQpq6RcJhzKb17A'} /> */}
      <Box sx={styles.row}>
        <Box sx={styles.textContent}>
          <TextContent
            title={'Get In Touch'}
            subtitle={'Queries? Questions?'}
            text={
              'If you have any questions or queries, send us a message and will get get back to you as soon as possible'
            }
          />
          <Phone sx={styles.phone} />
          <Address sx={styles.address} />
        </Box>
        <Box sx={styles.hours} style={styles.hours}>
          <HoursDisplay hours={businessData.hours} />
        </Box>
      </Box>
      <Box sx={styles.textContentForm}>
        <TextContent title={'Send Us A Message'} subtitle={'Here To Help'} />
      </Box>
      <Box sx={styles.contactFormContainer}>
        <ContactForm sx={styles.contactForm} style={styles.contactForm} />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
    // padding: '1rem',
  },

  row: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: ['0rem', '1rem'],
  },

  textContent: {
    padding: '0rem 1rem',
    width: ['100%', '', '50%'],
    flexGrow: '1',

    '.body': {
      maxWidth: '500px',
    },
  },

  hours: {
    width: ['100%', '', '50%'],
    flexGrow: '1',
    padding: '0rem 1rem',
    borderLeft: ['', '', 'solid 1px lightgrey'],
    '.innerHoursContainer': {
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      flexWrap: 'wrap',
      // 'div:last-child': {
      //   paddingRight: '0rem',
      // },
    },
    '.heading': {
      width: '100%',
      marginBottom: '1.5rem',
      color: 'grey',
      fontWeight: '300',
      textTransform: 'capitalize',
      letterSpacing: '2px',
      marginBottom: '1rem',
      fontSize: ['1.75rem', '2rem'],
      fontFamily: 'Playfair Display, serif',
    },
    '.typeContainer': {
      padding: '1rem 1rem 0rem 0rem',
      order: '2',
    },
    '.typelabel': {
      color: 'primary',
      textTransform: 'uppercase',
      marginBottom: '0.5rem',
      fontSize: '1.2rem',
    },
    '.day': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '1rem',
    },
    '.time': {
      fontFamily: 'Almarai, sans-serif',
      fontSize: '0.9rem',
      color: 'grey',
    },

    '.brunch': {
      order: '1',
    },
  },
  textContentForm: {
    padding: ['3rem 1rem', '', '3rem 2rem'],
    marginTop: ['2rem', '', '0rem'],
    backgroundColor: '#fff5f5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactFormContainer: {
    backgroundColor: '#fff5f5',
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: ['0rem 1rem 4rem', '', '0rem 2rem 4rem'],
    maxWidth: '600px',
    margin: '0 auto',
    '.submitBtn': {
      marginTop: '2rem',
      padding: '1rem 3rem',
    },
  },
  phone: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: 'grey',
    marginBottom: '1rem',
    fontSize: '1rem',
    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2',
    },
  },
  address: {
    color: 'grey',
    marginBottom: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    fontSize: '1rem',

    svg: {
      width: '16px',
      height: '16px',
      color: 'primary',
      marginRight: '2',
    },
  },
}
