import React from 'react'
import { Heading } from 'theme-ui'
export default function Subtitle(props) {
  return (
    <Heading
      as='h3'
      {...props}
      sx={{ ...styles.container, ...props.sx }}
      className='subtitle'
    >
      {props.children}
    </Heading>
  )
}

const styles = {
  container: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '1rem',
    fontFamily: 'Almarai, sans-serif',
  },
}
