import React from 'react'
import shortid from 'shortid'
import { Box, Text, Heading } from 'theme-ui'
import LinkSelector from '../ComponentSelectors/LinkSelector'

export default function ButtonRenderer({ buttons }) {
  return (
    <Box sx={styles.buttonsContainer} className={'buttonsContainer'}>
      {buttons.map((btn) => (
        <LinkSelector
          key={shortid.generate()}
          name={btn.name}
          link={btn.link}
          icon={btn.icon}
          sx={styles.button}
        />
      ))}
    </Box>
  )
}

const styles = {
  buttonsContainer: {},
  button: {
    variant: 'buttons.primary',
  },
}
